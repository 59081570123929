/*
 * @Author: MiaoshunLee
 * @Date: 2023-12-26 14:50:07
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-04-24 17:04:24
 * @Description:
 */
// 落地页路径 id
export const LANDING_PATH_MAP = {
  PATH_THREE_OUT_WX: 6 // 微信外 路径三
}

// 二级投放渠道id
export const MEDIA_ID = {
  VIDEO: 1002368 // 视频号
}

// 支付类型
export const PAY_TYPE_MAP = {
  WX: 2, // 微信支付
  ZFB: 1 // 支付宝支付
}

// 新表单弹窗 支付按钮样式
export const BTN_STYLE = {
  BTN: 'btn',
  PRICE_BTN: 'priceBtn'
}

// 倒计时排列方式
export const COUNT_DOWN_STYLE = {
  H: 'horizontal',
  V: 'vertical'
}

// 裂变活动落地页
// product_sn
export const FISSION_PRODUCT_SN = 'PRODUCT_SN_CQ_FISSION_ACT_7'

// 长轻分班兜底商品号
export const FENBAN_PRODUCT_SN = 'PRODUCT_SN_CQ_LIVE_7'

// site_id
export const FISSION_SITE_ID = '4937751' // 生产
// export const FISSION_SITE_ID = '2179246' // 测试

// 手机号验证
export const REX = /^1[3456789]\d{9}$/

// 环境
export const isDev = process.env.NODE_ENV !== 'prod'

// 分班问卷
export const changqingQuestioUrl = process.env.VUE_ENV === 'dev' ? 'https://test-course1.cqslim.net/' : 'https://course1.cqslim.net/'

// 问卷&加微页面 两套交互、样式
export const AB_STYLE = {
  OLD: 'B', // 原来的交互
  NEW: 'A' // 新交互
}

// 已经购买状态
export const CQ_BOUGHT_CODE = [
  30005, 30006
]

/**
 * 长轻验证码倒计时...
 */
export const CQ_CODE_TIME = 60

//
export const PATH_ID = {
  OUT_WX_1: 1, // 微信外一
  OUT_WX_2: 2, // 微信外二
  OUT_WX_3: 6, // 微信外三
  OUT_WX_4: 13, // 微信外四(实物专用)
  IN_WX_1: 3, // 微信内一
  IN_WX_2: 4, // 微信内二
  IN_WX_3: 14, // 微信内三(实物专用)
  MINI: 7, // 小程序
  MINI_1: 15, // 小程序(实物专用)
  H5_MINI: 12, // 小程序
  JW_OUT_WX_1: 16, // 微信外H5直接加微链路
  JW_IN_WX_1: 17, // 微信内H5直接加微链路
  MINI_2: 18, // 小程序二（手动留表单）
  MINI_3: 19 // 小程序路径（问卷简化版）
}

// 公钥
export const PUBLIC_KEY = `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA0Huxe5BXW9lMd8goqXe/
7xnQ8ssQpxj+xCtdRggDuLUvoHZb7CuLF9oR84UZgIZNQEm82FpyyOLWDWjAW3mH
ci4Ag0C8R2hKv4WHeFsaqY41k72N8yTR0ZSjXePh0TYzF7ICavykIw7YqLCpQb40
UzV4sC6HTtJfoBoaGiWBuvb18bvApjA7OjDKl8ZRKxkKnsz9pKsYAPX/375KdXCB
aaYqbbEfwehxOEH0cRJuzAREERJKUH+kRz680rypAIf0d7xIudWv750BhpZUyx0F
l1i1y5BaQ0+Tb8Pj0EHoY0bjeRr9OxZGjhyC/V5uTmv03U9eBBRKTqf1fdK8p3hF
TwIDAQAB
-----END PUBLIC KEY-----`

// 不用头图的落地页
export const NO_HEAD_PAGE_ID = process.env.VUE_ENV === 'dev'
  ? ['2266'] // 测试环境 pageid
  : ['3040'] // 生产环境 pageid

/**
 * 0元课程
 */
export const COURSE_PRODUCT_SN = [
  'sp_16443783170017155', // 长轻直播体验营(0元)
  'sp_16796535228452980' // 长轻营养食疗直播体验营（0元）
]
